@import "../../styles/_colors";

.report {
  .map {
    width: 100%;
    height: 100%;
    #infoDiv {
      position: absolute;
      top: 420px;
      right: 15px;
    }
  }
  .map3d {
    width: 100%;
    height: 100%;
  }

  .smartshape {
    width: 100%;
    height: 100%;
  }

  .display-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  width: 100%;
  display: flex;
  overflow: hidden;
  box-shadow: -2px -2px 1px -1px rgba(0, 0, 0, 0.2);

  &--menu_container {
    display: inline-flex;
    flex-shrink: 0;
    background: white;
    height: 100%;
    box-shadow: -2px -2px 1px -1px rgba(0, 0, 0, 0.2);
  }

  &--body {
    width: 100%;
    display: flex;
    overflow: hidden;
    flex-flow: column;

    .nav.nav-tabs {
      margin: 17px 0 5px;
      padding: 0 20px;

      .nav-link.active,
      .nav-link:hover {
        background-color: transparent;
        color: $secondary;
        border-bottom: 2px solid $secondary;
      }

      .nav-item {
        flex-grow: 1;
        text-align: center;
      }
    }

    &_container {
      overflow: hidden;
      display: inline-flex;
      flex-grow: 1;

      .report-table__networkID.clickable {
        cursor: pointer;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
          color: $secondary;
        }
      }
    }

    &_table_container {
      overflow: hidden;
      display: inline-flex;
      flex-grow: 1;

      .network-table {
        padding: 15px 20px 0;
      }

      .report-table__networkID.clickable {
        cursor: pointer;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
          color: $secondary;
        }
      }
    }

    &_map_container {
      display: inline-flex;
      flex-grow: 1;
    }
  }

  .report-table,
  .sz-table {
    th {
      min-width: 120px;
    }
  }
}
