@import '../../../styles/colors';

.gaia-control-buttons {
    border-bottom: 1px solid $fourth-lighter ;
    padding-top: 5px;
    margin-left: 0;
    margin-right: 0;
    background: #FFFFFF ;
    width: 100%;
    height: 80px;
    flex-flow: column;

    .gaia-control-button {
        padding: 0.300rem 0.75rem;
        background: $primary-color;
        color: white;
        display: inline-flex;
        border: 1px solid transparent;
        box-shadow: unset;
        width: 100%;
        justify-content: center;

        &.disabled{
            background: $third-color;

            &:hover {
                cursor: not-allowed;
            }
        }

    }

}

