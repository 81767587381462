@import "../../styles/_globals";

.vision-container {
  height: calc(100% - 60px);
  display: flex;
  flex-wrap: nowrap;

  &.connected {
    height: 100%;
    width: 100%;
    transition: 0.5s ease-in;
  }
  &.isOpen {
    transition: 0.3s ease;
  }
}
.gaia-aside {

  height: $body-height;

  &.sz-aside.sz-aside-enter-done {
    width: 100%;

    .sz-aside__item--title {
      opacity: 1;
    }
  }

  @media screen and (min-width: 768px) {
    &.sz-aside.sz-aside-enter-done {
      width: 200px;
    }
  }

  nav {
    display: flex;
    flex-wrap: wrap;
    height: 100%;

    ul {
      overflow: hidden;
      justify-content: space-between;
    }

    .gaia-vision--version-container {
      display: flex;
      align-self: flex-end;
      justify-content: center;
      padding-bottom: 15px;
    }
  }

}
.gaia-main-container {
    display: flex;
    height: $body-height;
}
.no-padding{
  padding: 0;
}

.clickable {
  cursor: pointer !important;
}

.container-content{
  width: 100%;
}

.report-icon {
  background-image: url("../../assets/img/icons/report.png");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
}


.report-icon.active {
  background-image: url("../../assets/img/icons/report_blue.png");
}
