.contract-details {
  &-col {
    background-color: var(--contract-detail-box-background);
  }

  &--logo-drop-zones {
    border: 2px solid var(--drop-zone-border);

    align-content: flex-end;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }

  &-preview {
    background-color: var(--contract-detail-box-background);
  }
}
