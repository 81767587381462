@import url("https://js.arcgis.com/4.21/esri/css/main.css");
@import "../../styles/colors";

.map {
    display: inline-flex;
}

#viewDiv {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
}

.hidden {
    display: none;
}

#infoDiv {
    position: absolute;
    top: 354px;
    right: 15px;
}

#infoDiv input {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px;
}
.esri-layer-list__item-toggle {
    .esri-icon-visible {
        color: $secondary;
    }
}

.esri-view-height-greater-than-medium .esri-ui-corner .esri-component .esri-expand__content {
    max-height: 999px;
}

.esri-view-height-medium .esri-ui-corner .esri-component .esri-expand__content {
    max-height: 650px;
}

.esri-view-height-small .esri-ui-corner .esri-component .esri-expand__content {
    max-height: 600px;
}

.esri-view-height-xsmall .esri-ui-corner .esri-component .esri-expand__content {
    max-height: 400px;
}

.esri-slider--horizontal {
    margin-top: 17px;

    .esri-slider__label {
        top: -27px;
    }
}

.esri-view-height-medium .esri-expand .esri-widget--panel,
.esri-view-height-small .esri-expand .esri-widget--panel,
.esri-view-height-xsmall .esri-expand .esri-widget--panel,
.esri-view-height-greater-than-medium .esri-expand .esri-widget--panel {
    max-height: inherit;
}

.btn-switch-3d {
    position: absolute !important;
    top: 354px;
    right: 1%;
    margin-right: -4px;
    width: 32px !important;
    height: 32px !important;
    background: white !important;

    .icon-resize {
        font-size: 26px;
    }
}

.btn-map-fullscreen {
    position: absolute !important;
    top: 219px;
    margin-left: 15px;
    width: 32px !important;
    height: 32px !important;
    background: white !important;
}
