@import "../../styles/_colors";

.profil {
  width: 100%;
  background-color: white;
  .profil-container {
    padding: 25px 50px;
    box-shadow: -2px -2px 1px -1px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 1000px;
  }

  .profil-info-container {
    display: inline-flex;
    align-items: baseline;
    border: 2px solid var(--toolbar);
    padding: 1rem;
    .user-info {
      display: list-item;
      width: 80%;
      list-style-type: none;
      .name {
        font-weight: bold;
        font-size: 1rem;
      }
    }
    .buttons {
      display: inline-flex;
      align-items: baseline;
      width: 40%;
      height: 80%;
    }
  }

  .profil-form-container {
    padding-top: 1rem;
    width: 50%;
  }
  button {
    background: white;
    border: 2px solid rgba(0, 0, 0, 0.2) !important;
    margin-left: 5px !important;
  }
  .sz-btn {
    &:active,
    &:hover,
    &:visited,
    &:focus {
      background: rgba(0, 0, 0, 0.2) !important;
      background-color: rgba(0, 0, 0, 0.2) !important;
    }
  }
  .nav.nav-tabs .nav-link.active {
    border: 0px;
    border-bottom: 2px solid #239de1;
    color: #239de1;
    background-color: white;
  }
}
