.block-header{
  &--container{
    display: flex;
    align-content: flex-start;
    padding: 12px;
  }

  &--text-container {
    flex-flow: column;
  }

  &--icon {
    padding-right: 6px;
    margin-top: 10px;
  }
}
