@import "../../styles/colors";

.gaia-control-buttons button {
  height: 33px;
}

.gaia-search-filters {
  padding: 0 12px;
  max-width: 100%;

  @media (max-width: 1400px) {
    max-height: 600px;
    margin-bottom: 10px;
    overflow: scroll;
  }

  &__indice {
    .sz-collapse{
      &__header{
        padding-left: 15px !important;

        .w-100 {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }

  &__category,
  &__no-subcategories {
    .sz-collapse {
      margin-top: 2px;
      margin-bottom: 0 !important;

      .sz-collapse__header {
        padding: 1rem;

        .border-right {
          border: none !important;
          padding: 0.4rem !important;
        }
      }
    }

    color: #ffffff;

    &-title {
      opacity: 1;
      color: #ffffff;

      @media (max-width: 1400px) {
        font-size: 14px;
      }
    }

    &-check {
      overflow: hidden;
      & input {

      }
      & label {
        font-size: 12px;
      }
    }

    .sz-left-border::before,
    .sz-collapse__header::after {
      background: none;
    }

    .sz-left-border::after {
      display: none;
    }

    .sz-collapse__body::before,
    .sz-collapse__footer::before {
      background: none;
    }

    .pl-4 {
      padding-left: 0 !important;
    }

    .p-3 {
      padding: 0.7rem !important;
    }

    .border-left {
      border-left: none !important;
    }

    li {
      list-style-type: none;
      margin: 7px 0;
    }
  }

  &__category .sz-collapse .sz-collapse__header {
    background: #072b4e 0 0 no-repeat padding-box !important;

    .bg-white {
      background-color: unset !important;
    }

    .my-auto {
      padding: 0 !important;
      margin: 6px 0 0 0 !important;
    }
  }

  &__no-subcategories .sz-collapse .sz-collapse__header {
    background: #afb8ce 0 0 no-repeat padding-box !important;

    .bg-white {
      background-color: unset !important;
    }
    .my-auto {
      padding: 0 !important;
      margin: 6px 0 0 0 !important;
    }
    label {
      width: 75%;
    }
  }

  &__subcategory {
    font-size: 12px;
    background: #ffff;
    margin: 0.5rem 0;
    width: 100%;
    color: #072B4E;

    &-item-title {
      margin-right: 5px;
    }

    &-item .custom-control-input {
      z-index: 1 !important;
    }

    &-item-count {
      font-weight: bold;
    }
  }

  &::after {
    content: "";
    border: solid #072b4e;
    position: absolute;
    border-width: 100px 0.5px;
    right: 0;
    top: 0;
    height: -webkit-fill-available;
  }
}

.filter-spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.5);
  height: 70%;

  .spinner-border {
    position: absolute;
    top: 20%;
  }
}

.gaia-search-filters__category,
.gaia-search-filters__no-subcategories {
  .sz-collapse {
    .sz-collapse__header {
      padding: 0.4rem;

      .w-100 {
        padding-top: 0.5rem;
      }
    }
  }

  .custom-control-label::before{
    border-color: $secondary;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $secondary;
    background: $secondary;
  }
}

.gaia-filter-side {
  overflow-y: auto;
  height: calc(100% - 80px);
  position: absolute;
  width: 100%;
  border-right: 1px solid $fourth-lighter;
}

.radio-filter .custom-control-label::before {
  border-radius: 50% !important;
}

.reset-button {
  font-size: 1rem;
  color: #8a9396;
}

.reset-button.active {
  cursor: pointer;
  color: #239ee0;
}

.report-menu-tabs-list-option-container {
  display: inline-flex;
  width: 100%;
  .color-bar {
    width: 7%;
    margin: 3px 12px;
    &.green {
      background-color: green;
    }
    &.yellow {
      background-color: yellow;
    }
    &.red {
      background-color: red;
    }
    &.orange {
      background-color: orange;
    }
    &.grey {
      background-color: grey;
    }
    &.blue {
      background-color: blue;
    }
    &.black {
      background-color: black;
    }
    &.line {
      background-color: rgb(0, 195, 0);
    }
    &.squash-blossom {
      background-color: rgb(255, 211, 105);
    }
    &.good-samaritan {
      background-color: rgb(63, 103, 130);
    }
  }
  .option-text {
    width: 80%;
  }
}