@import "../../../styles/_colors";

.gaia-search {
    width: 558px;
    display: flex;
    align-items: stretch;
    height: 100%;
    margin-bottom: 0;
}
.sz-form-group {
    height: 100%;
    &__input-group-icon{
        z-index: 1 !important;
        height: 36px;
    }

}

.gaia-search__input {
    height: 36px;
    width: 513px;

    &-clear {
        cursor: pointer;
        z-index: 5;
        right: 45px;
        height: 100%;
        width: 26px;
        font-size: 1.12rem;
        border-right: $third-color 0.5px solid;
    }
}

.sz-form-group {
    @at-root #{&}__input-group-icon {
        z-index: 5;
        width: 45px;
        font-size: 1.12rem;
        border: white 0.5px solid;
        background-color: #CDD2E0;
        color: white !important;
        cursor: pointer;

        &:focus,
        &:active
            {
            background-color: #CDD2E0;
            color: white !important;

            &+.focused {
                background-color: #CDD2E0;
                color: white !important;
                border: 2px solid $primary-color-lighter;
                border-left: none;
            }

        }

    }

    @at-root #{&}__sz-input-text:focus {
        border: 2px solid $primary-color-lighter;
    }

    .spinner-border{
        z-index: 5;
        left: 516px;
        top: 2px;
        font-size: 0.12rem;
    }

}

.gaia-suggestions {
    position: absolute;
    top: 40px;
    z-index: 10;
    color: #072B4E;
    background: $third-color 0% 0% no-repeat padding-box;
    opacity: 1;
    font-family: 'DINPro';
    width: 29%;
    padding-left: 0;

    &_hidden {
        visibility: hidden;
    }

    li {
        list-style-type: none;
        border-block-end: $secondary-color solid 0.5px;
        font-size: 17px;
        font-style: italic;
        padding-left: 1rem;
        line-height: 2rem;
        letter-spacing: 0;
        font-weight: lighter;

        &:hover,
        &.suggestion-active {
            background-color: $secondary-color;
            opacity: 1;
            color: white;
            cursor: pointer;
            font-weight: 700;
        }
    }

    li:last-child {
        border-block-end: none;
    }
}


.no-suggestions {
    color: #999;
    padding: 0.5rem;
}




.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
}
