@import "../../styles/_globals";

.gaia-header {
  height: $header-height;

  h1.page-title {
    font-size: 1.5rem;
    margin-bottom: 0;
    display: flex;
    align-self: center;
    margin-left: 20px;
    font-family: "DINPro-Regular", sans-serif;
  }
  .sz-icon-bold.arrow-down-1 {
    display: none;
  }

  .sz-dropdown .dropdown-menu:after, .sz-dropdown .dropdown-menu:before {
    left: 93%;
  }

  .dropdown-menu.show {
      transform: translate(-300px, 44px) !important;
      width: 352px;
      z-index: 1001;
    }

  &.unlogged {
    padding-right: 20rem;
  }
  @at-root #{&}__brand {
    padding: 2px 0;
    padding-left: 0.5rem !important;
  }
  @at-root #{&}__menu-icon {
    font-size: 2rem;
    justify-content: flex-end;
    padding-right: 20px;
    cursor: pointer;
    display: inline-flex;
    width: 50px;
    transition: width 0.5s ease;

    &__text {
      display: block;
      position: absolute;
      left: 60px;
      font-size: 1.5rem;
      line-height: 2.8rem;
      transition: 0.25s ease;
    }

    span {
      background: #030e40;
      display: block;
      height: 4px;
      left: 7px;
      top: 18px;
      opacity: 1;
      position: absolute;
      transition: 0.25s ease-in-out;
      width: 12px;
    }

    &.menuIsOpen {
      width: 200px;

      .gaia-header__menu-icon__text {
        opacity: 1;
      }

      span:nth-child(1) {
        left: 16px;
        top: 15px;
      }
      span:nth-child(2) {
        left: 23px;
        top: 15px;
      }
      span:nth-child(3) {
        left: -20px;
        opacity: 0;
      }
      span:nth-child(4) {
        left: 50px;
        opacity: 0;
      }
      span:nth-child(5) {
        left: 16px;
        top: 22px;
      }
      span:nth-child(6) {
        left: 23px;
        top: 22px;
      }
      span:nth-child(2),
      span:nth-child(5) {
        transform: rotate(-45deg);
      }
      span:nth-child(1),
      span:nth-child(6) {
        transform: rotate(45deg);
      }
    }
    &.menuIsClose {
      .gaia-header__menu-icon__text {
        opacity: 0;

        p {
          margin-bottom: 0;
        }
      }

      span:nth-child(1),
      span:nth-child(2) {
        top: 10px;
      }

      span:nth-child(3),
      span:nth-child(4) {
        top: 18px;
      }

      span:nth-child(5),
      span:nth-child(6) {
        top: 26px;
      }

      span:nth-child(2n) {
        left: 24px;
      }
      span:nth-child(2n + 1) {
        left: 12px;
      }
    }
  }
  @at-root #{&}__search-form {
    padding: 4px;
    margin: 0 auto;
  }
  @at-root #{&}__app-icon {
    font-size: 1.7rem;
  }
  @at-root #{&}__notif-icon {
    font-size: 2rem;
  }
  @at-root #{&}__signin {
    font-size: 1.8rem;
  }
  @at-root #{&}__signin-text {
    font-size: 1rem;
  }
  @at-root #{&}__brand-title {
    font-size: 1.3rem;
    padding-top: 11px;
    padding-right: 10rem !important;
  }
}
.gaia-sub-header {
  height: 52px;
}
