.Toolbar {
    right: 0;
    left: 0;
    top: 5%;
    width: 100%;
    background-color:#222;
    border: 2px solid #888;
    border-radius: 7px;
    padding: 2px;
    z-index: 10;
    text-align: center;
}

.Toolbar > .ZoomIn,
.Toolbar > .ZoomOut {
    border: 1px solid #888;
    border-radius: 5px;
    background-color: #FE8;
    width: 32px;
    height: 24px;
    font-size: 12px;
    font-weight: bold;
    color: black;
    margin: 2px;
}

.Toolbar > .ZoomPercent {
    display: initial;
    font-size: 16px;
    color: white;
    margin: 2px;
}

