@import "../../../styles/_colors.scss";

.gaia-search-data-count {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    height: 80px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $fourth-lighter ;
    padding-left: 10px;
}
