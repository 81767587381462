@import '../../../styles/colors';

.collapse-toggle{
  position: relative;
  //left: 0;
  //height: 100%;
  background: #fff;
  width: 28px;
  border-right: 2px solid rgba(0, 0, 0, 0.125);
  border-left: 1px solid rgba(0, 0, 0, 0.125);

  .collapse-toggle-hover-box {
    background-color: transparent;
    position: absolute;
    width: 15px;
    top: 50%;
    right: -1px;
    transition-duration: 0.35s;
    z-index: 1;


    .collapse-toggle-container {
      position: absolute;
      top: 20%;
      width: 13px;
      left: 0;
      height: 40px;
      background-color: var(--primary);
      cursor: pointer;

      .collapse-toggle-default {
        color: white;
        opacity: 1;
        left: -5px;
        top: 6px;
        position: absolute;
      }
    }
  }
}

.collapse-close {
  transform: rotate(45deg);
  display: block;
  height: 37px;
}