@import "../../../styles/_colors";

.autocomplete {
  width: 100%;

  .mb-3 {
    margin-bottom: 0 !important;
  }

  .autocomplete-input input{
    font-family: "DINPro-Regular", sans-serif;
  }

  &-options {
    border: 1px solid $fourth-lighter;

    &-header {
      padding: 5px;
      font-size: 1rem;
      font-weight: bold;
      background-color: $fourth-lighter;
    }

    &-loader {
      padding: 0 10px;
    }

    &--option {
      padding: 5px;
      cursor: pointer;

      &:hover {
        background-color: $fourth-lighter;
      }

      &_none {
        font-style: italic;
        background-color: $third-color;
        color: white;

        &:hover{
          background-color: $third-color;
          cursor: not-allowed;
        }
      }
    }
  }
}