@import "../../styles/_colors";

.gaia_breadcrumbs{
  display: flex;
  font-size: 0.8125rem;
  padding: 5px;
  border-bottom: 1px solid $fourth-lighter;
  background-color: #66ffff;

  .gaia_breadcrumb {
    display: inline-flex;

    &.clickable:hover{
      color: $secondary;
      text-decoration: underline $secondary;
    }

    &--separator {
      width: 25px;
      font-size: 15px;
      text-align: center;
      height: 26px;
    }
  }
  .gaia_breadcrumbs-search_return {
    float: left;
    display: inline-flex;
  }

  .breadcrumb{
    margin: 0;
    padding: 0;
    background-color: transparent;
    flex-wrap: nowrap;

    .clickable a:hover{
      color: $secondary;
      text-decoration: none;
    }

    .estate_object_name a:hover {
      text-decoration: none;
      cursor: initial;
    }
  }
}

