@import "../../../styles/_colors";

.gaia-report-fav-filter_update-modal{
  display: flex;

  &-filter {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    border: 1px solid $secondary-color;
    margin-bottom: 10px;

    &-title {
      display: inline-flex;
    }

    &-actions {
      display: inline-flex;
      align-items: center;
      flex-shrink: 0;
      &_checkbox {
        display: inline-flex;
      }
      &_radio {
        display: inline-flex;
        &.sz-radio .custom-control-label::before{
          top: 0.15625rem
        }
      }
    }
  }

  &-footer{
    margin-top: 35px;
    display: flex;
    flex-wrap: wrap;

    .no-default-fav {
      flex-shrink: 0;
      width: 100%;
    }

    button {
      width: 150px;
      margin-right: 20px;
    }
  }
}