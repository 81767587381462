@import "../../../styles/_colors";

.sz-form-group {
    height: 100%;
    &__input-group-icon{
        z-index: 1 !important;
    }

}

.sz-form-group {
    @at-root #{&}__input-group-icon {
        z-index: 5;
        width: 45px;
        font-size: 1.12rem;
        border: white 0.5px solid;
        background-color: $secondary-color;
        color: white !important;
        cursor: pointer;

        &:focus,
        &:active
            {
            background-color: $secondary-color;
            color: white !important;

            &+.focused {
                background-color: $secondary-color;
                color: white !important;
                border: 2px solid $primary-color-lighter;
                border-left: none;
            }

        }

    }

    @at-root #{&}__sz-input-text:focus {
        border: 2px solid $primary-color-lighter;
    }

    .spinner-border{
        z-index: 5;
        left: 516px;
        top: 2px;
        font-size: 0.12rem;
    }

}

