.gaia-pdf {
    float: left;
    max-width: 100%;
    height: 85%;
    overflow: scroll;
}
.gaia-progress-bar{
    width: 35%;
    margin-left: 90%;
}
