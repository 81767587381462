@import "./styles/_colors";
@import './styles/fonts';

html, body, #vision-app {
  height: 100%;
  background-color: #EAEDF4;
  font-family: 'DINPro-Regular';
}
.gaia-content-container {
  max-width: 1100px;
}

.text-secondary {
  color: $primary-color !important;
}

.sz-aside__item {
  &.active, &.active:visited {
    color: $primary-color;
    background: none;
    &.report_active {
      color: $secondary-color !important;
    }
  }
  &.active:hover {
    color: $primary-color;
    background: none;
  }
}
