@import "../../styles/_colors";

.history {

  width: 100%;
  background-color: white;
  .history-container {
  padding: 25px 50px;
  box-shadow: -2px -2px 1px -1px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  }

  .history-search{
    height: unset;
    width: 513px;
    display: flex;

    .sz-form-group__sz-input-text{
      height: 40px;
    }
  }

  //.react-bootstrap-table{
  //  overflow: scroll;
  //}

  &-table {
    width: 100%;
    border: 1px solid $fourth;

    .sz-table-header, tbody > tr {
      display: flex;

      th, td {
        display: inline-flex;
        &:first-child {
          flex-grow: 1;
          &::after{
            width: 0;
          }
        }
        &:last-child {
          width: 300px;
          flex-shrink: 1;
        }
      }
    }

    tbody > tr {
      cursor: pointer;

      &:hover {
        background-color: $fourth-lighter;
      }
    }

    .sz-table-header th {
      font-size: 1.2rem;
      padding: 15px 0.75rem;
    }

  }
}
