@import '~leaflet/dist/leaflet.css';

//::-webkit-scrollbar {
//  width: 10px;
//}
//
///* Track */
//::-webkit-scrollbar-track {
//  background: #f1f1f1;
//}
//
///* Handle */
//::-webkit-scrollbar-thumb {
//  background: #888;
//}
//
///* Handle on hover */
//::-webkit-scrollbar-thumb:hover {
//  background: #555;
//}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  box-shadow: 0 0 1px rgba(255,255,255,.5);
}


.leaflet-container {
  width: auto;
  height: 100%;
  min-height: 500px;
  position: relative;
}

.marker-cluster-custom {
  background: var(--marker-cluster-bg);
  border: 2px solid var(--marker-cluster-border);
  border-radius: 50%;
  color: var(--marker-cluster-color);
  line-height: 26px;
  text-align: center;

  &__planned {
    @extend .marker-cluster-custom;
    background: var(--status-planned);
  }

  &__ongoing {
    @extend .marker-cluster-custom;
    background: var(--status-ongoing);
  }
}

.intervention-details-container {
  left: 21px;

  article {
    background: var(--tile-bg);
    overflow-y: auto;

    header {
      background-color: var(--box-header-light);
    }

    > div {
      background: var(--tile-bg);

      &.itv-type {
        background: var(--type-tile-bg);
      }
    }
  }
}

.leaflet-container {
  .leaflet-bar {
    button {
      border: none;
      border-radius: 2px;
      width: 44px;
      height: 44px;
      padding: 8px;
    }
  }
}

.box-shadow {
  padding: 12px 24px;
  background: white;
}

.map-legend {
  position: absolute;
  display: none;
  right: 57px;
  top: 0;
  width: 260px;
  overflow-y: scroll;
  max-height: 420px;

  &.show {
    display: block;
  }

  .list-group {
    .list-group-item {
      padding: 4px 0;
      border: none;
      font-size: 1.6rem;
    }
  }

  .legend-title {
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: var(--map-legend-title-text-color);
  }

  .legend-marker {
    display: inline-block;
    width: 30px;
    height: 33px;
    margin-right: 14px;
  }

  .legend-item {
    font-size: 1rem;
  }
}

.network-filter {
  width: 335px;
  position: absolute;
  display: none;
  right: 57px;
  top: 0;
  overflow-y: scroll;
  max-height: 420px;

  &.show {
    display: block;
  }

  .network-title {
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: var(--map-legend-title-text-color);
  }
}

.leaflet-popup-content {
  white-space: nowrap;
  line-height: 1px !important;
  font-size: 11px;
  width: auto;
}

.eca-popup {
  background-color: #d9f2fa;
  font-size: 14px;
  font-weight: bold;
  line-height: 25px;
  padding-left: 6px;
}

.asset-popup {
  font-size: 14px;
  font-weight: bold;
}

.regard-tooltip {
  &::before {
    display: none;
  }
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
  font-size: 12px;
  font-weight: bold;
}

.t_plt_point_a {
  z-index: 1 !important;
}

.network-label {
  width: 100%;
}