@import '../../styles/colors';

.gaia-search-result {
  height: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  -webkit-box-shadow: -2px -2px 1px -1px rgba(0,0,0,0.20);
  -moz-box-shadow: -2px -2px 1px -1px rgba(0,0,0,0.20);
  box-shadow: -2px -2px 1px -1px rgba(0, 0, 0, 0.20);

  &-container {
    display: inline-flex;
  }

  .sz-aside.sz-aside-exit-done {
    width: 78px;
  }

  .sz-aside__list {
    padding-right: 15px;
    padding-left: 15px;

    li,
    a {
      padding: 17px 0;
    }
  }

  .gaia-search-category {
    color: #239ee0;
    &__item-title {
      font-size: 11px;
      // filter: inherit;
    }

    a {
      text-align: center;
    }

    .active {
      background: none;
      color: inherit;
      filter: brightness(1);

      .gaia-search-category__item-icon {
        color: inherit;
      }

      &::after {
        content: "";
        border: solid #239ee0;
        position: absolute;
        bottom: 1rem;
        border-width: 48px 2px;
        right: 0;
        left: 58px;
        filter: brightness(1);
      }
    }

  }

  .gaia-search-data {
    overflow-y: auto;
    height: calc(100% - 80px);
    padding-left: 0;
    margin-bottom: 0;

    &__item {
      opacity: 1;
      list-style-type: none;
      padding: 15px 2%;
      letter-spacing: 0;
      color: #072B4E;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      &.active {
        font-weight: bold;
        padding: 20px 2%;
        color: #009EE0;
      }

      &-content {
        text-align: left;
        width: calc(100% - 90px);
        display: inline-flex;
        flex-direction: column;
        word-break: break-word;

        @media screen and (max-width: 1200px) {
          width: calc(100% - 55px);
        }

      }

      &-content-title {
        display: flex;
      }

      &-content-more {
        font-size: 11px;
        display: flex;
      }

      &-links {
        width: 90px;
        display: flex;
        flex-shrink: 1;

        @media screen and (max-width: 1200px) {
          flex-direction: column;
        }
        .gaia-search-data__item-links-icon {
          display: flex;
          padding-right: 7px;
          cursor: pointer;

          &.empty {
            width: 21px;
          }
        }

        span{
          margin-left: 5px;
        }

      }

      &-type {
        padding-left: 5px;
        width: 20px;
        display: block;
        flex-shrink: 1;
        justify-content: space-around;

        @media screen and (max-width: 1200px) {
          flex-direction: column;
        }
        .gaia-search-data__item-links-icon {
          display: flex;
          font-size: 1.2rem;
        }

        span{
          margin-left: 5px;
        }

      }
    }
    &__count{
      padding: 10px;
    }
  }


  .category-tab {
    height: 100%;

    &.active {
      background: none;
      max-width: 550px;
      width: 550px;
    }
  }

  .category-filters {
    height: 100%;
  }
}

.gaia-search__expand-or-collapse {
  display: flex;
  align-self: center;
  padding-bottom: 35px;
}

.button-invisible {
  background-color: transparent;
  border: none;

  &:active,
  &:focus {
    outline: none;
  }
}

.gaia-search__aside {
  width: 78px;
  display: inline-flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100%;
  border-right: 1px solid $fourth-lighter;

  &__item {
    display: flex;
    align-content: center;
    justify-items: center;
    justify-self: center;
  }
}

.gaia-search__main-tab-result {
  margin: 0;
}
