.leaflet-control-layers-toggle.leaflet-layerstree-named-toggle {
    margin: 2px 5px;
    width: auto;
    height: auto;
    background-image: none;
}

.leaflet-layerstree-children {
    /*padding: 10px;*/
}

.leaflet-layerstree-node {
    padding: 2px 5px;
}

.leaflet-layerstree-node > .leaflet-layerstree-children {
    padding-left: 20px;
}

.leaflet-layerstree-children-nopad {
    padding-left: 0;
}

.leaflet-layerstree-header input{
    margin-left: 0;
}

.leaflet-layerstree-header {
}

.leaflet-layerstree-header-pointer {
    cursor: pointer;
}

.leaflet-layerstree-header label {
    display: inline-block;
    cursor: pointer;
}

.leaflet-layerstree-header-label {
    margin-bottom: 0;
}

.leaflet-layerstree-header-name {
    margin-left: 35px;
}

.leaflet-layerstree-header-space {
}

.leaflet-layerstree-closed {
}

.leaflet-layerstree-opened {
}

.leaflet-layerstree-hide {
    display: none;
}

.leaflet-layerstree-nevershow {
    display: none;
}

.leaflet-layerstree-expand-collapse {
    cursor: pointer;
}

/* Hide root toggle */
.leaflet-control-layers-base > .leaflet-layerstree-header  .leaflet-layerstree-header-name {
    font-size: 1rem;
}

.leaflet-control-layers-overlays > .leaflet-layerstree-header {
    display: none;
}

.leaflet-layerstree-header-label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.leaflet-layerstree-header-label input {
    position: absolute;
    top: -20px;
    width: 0;
    height: 0;
}

.leaflet-layerstree-header-label input + span.leaflet-control-layers-checkbox-toggle {
    width: 24px;
    height: 14px;
    position: absolute;
    top: 3px;
    background: #99b4df;
    border-radius: 15px;
}
.leaflet-layerstree-header-label input + span.leaflet-control-layers-checkbox-toggle:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 2px;
    width: 8px;
    height: 8px;
    background: white;
    border-radius: 50%;
    transform: translateY(-50%);
    transition: all .5s;
}
.leaflet-layerstree-header-label input:checked + span.leaflet-control-layers-checkbox-toggle{
    background: #346abf;
}

.leaflet-layerstree-header-label input:checked + span.leaflet-control-layers-checkbox-toggle:before {
    left: 13px;
}